a {
  text-decoration: none;
}

.projects-page-container {
  background-color: #e6ccb2;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.projects-window-title-text {
  font-size: 3rem;
  margin: 0;
  padding: 10px 10px 5px 10px;
}

.projects-window-container {
  max-width: 90%;
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 40px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  transition: ease all 0.5s;
}

.projects-window-title-container {
  display: flex;
  justify-content: center;
}

.project-card-container {
  background-color: #d7b18b;
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 10px;
  transition: ease all 0.5s;
  display: flex;
  flex-direction: column;
}

.test {
  margin-left: 2.5%;
  margin-top: 5px;
  width: 95%;
}

/* .project-preview-container {
  width: 95%;
  margin-left: 2.5%;
  margin-top: 5px;
} */

.project-preview-img {
  width: 100%;
  display: block;
}

.project-card-title-container {
  width: 100%;
  margin-top: 5px;
}

.project-card-techused-container {
  width: 100%;
  margin-top: 5px;
}

.project-card-title-container h1 {
  font-size: 2.5rem;
  margin: 0;
  text-align: center;
}

.project-card-title-container:hover {
  background-color: #ecd0b5;
}
.project-card-title-container:active {
  background-color: #ebc29c;
}

.project-card-main-container {
  width: 95%;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 2.5%;
}

.project-card-techused-container h2 {
  margin: 0;
  font-family: IBM-Plex-Sans-Thai-Bold;
  font-size: 1.7rem;
  text-align: center;
  color: #9c6644;
}

.project-card-main-text-container {
}

.project-card-main-text-container p {
  margin: 0;
  padding-left: 5px;
  font-family: IBM-Plex-Sans-Thai;
  font-size: 1rem;
  color: #9c6644;
}

@media (min-width: 500px) {
  .test {
    margin-top: 10px;
  }
  .project-card-title-container {
    margin-top: 10px;
  }

  .project-card-techused-container {
    margin-top: 10px;
  }
  .project-card-title-container h1 {
    font-size: 3rem;
  }

  .project-card-techused-container h2 {
    font-size: 2rem;
  }

  .project-card-main-container {
    margin-top: 10px;
  }

  .project-card-main-text-container p {
    font-size: 1.2rem;
  }
}

@media (min-width: 1200px) {
  .projects-window-container {
  }
  .project-card-container {
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 10px;
    flex-direction: row;
  }

  .project-preview-img {
    width: 100%;
    margin-top: 10px;
    margin-left: 2.5%;
    display: block;
    margin: 0;
  }

  .project-card-title-container {
    width: 100%;
    margin-left: 0;
  }

  .project-card-techused-container {
    width: 100%;
    margin-left: 0;
  }

  .project-card-main-container {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .project-card-main-text-container {
    width: 100%;
  }

  .test {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
  }

  .project-card-main-container {
    width: 50%;
  }
  .project-card-main-text-container p {
    font-size: 1.3rem;
  }
}

@media (min-width: 1700px) {
  .project-card-main-text-container p {
    font-size: 1.5rem;
  }
}
