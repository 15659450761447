.home-page-container {
  background-color: #e6ccb2;
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 5px solid #e6ccb2;
  position: relative;
}

.home-text-container {
  max-width: 90%;
  margin-left: 5%;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  transition: ease all 0.5s;
}

.home-body-text-title-container {
  display: flex;
  width: 100%;
}

.navbar-hamburger-menu {
  display: none;
}

.home-body-text-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 30px 30px;
  transition: ease all 0.5s;
}

.home-intro-text-container {
  width: 100%;
  border-radius: 30px 30px 0 0;
  transition: ease all 0.5s;
}

.home-intro-text {
  margin: 0;
  font-family: IBM-Plex-Sans-Thai;
  font-size: 2.8rem;
  padding: 20px 20px 0 20px;
  color: #9c6644;
  text-align: center;
  transition: ease all 0.5s;
}

.home-body-text-title {
  margin: 0;
  padding: 10px 20px 0px 20px;
  font-family: IBM-Plex-Sans-Thai;
  font-size: 2.2rem;
  color: #9c6644;
  text-align: center;
  justify-content: space-between;
  transition: ease all 0.5s;
}

.home-body-text-title b {
  font-size: 2rem;
  transition: ease all 0.5s;
}

.home-body-text {
  margin: 0;
  padding: 0px 20px 20px 20px;
  font-family: IBM-Plex-Sans-Thai;
  font-size: 1.7rem;
  color: #9c6644;
  text-align: center;
  transition: ease all 0.5s;
}

.ben-picture {
  display: none;
  max-height: calc(100%);
  width: auto;
  position: absolute;
  right: calc(0px - 150%);
  bottom: 0;
  z-index: 0;
  transition: ease all 1s;
}

b {
  font-weight: normal;
  font-family: IBM-Plex-Sans-Thai-Bold;
}

.show-775px {
  display: none;
}

.social-icons-container {
  display: flex;
  justify-content: space-around;
  width: 75%;
  margin-left: 12.5%;
  margin-top: 10px;
}

.social-icons-container svg {
  height: 70px;
  width: auto;
}

@media (max-width: 460px) {
  .home-intro-text {
    font-size: 2.6rem;
  }

  .home-body-text-title {
    font-size: 2rem;
  }

  .home-body-text-title b {
    font-size: 1.6rem;
  }

  .home-body-text {
    font-size: 1.4rem;
  }
}

@media (min-width: 500px) {
  .home-intro-text-container {
    width: 65%;
    margin-left: 17.5%;
  }

  .home-body-text-container {
    border-radius: 30px 30px 30px 30px;
  }

  .home-body-text-title {
    font-size: 2.5rem;
  }

  .home-body-text-title b {
    font-size: 2.5rem;
  }
}

@media (min-width: 700px) {
  .ben-picture {
    display: block;
  }
  .home-text-container {
    width: 80%;
    margin-left: 10%;
  }
  .home-intro-text-container {
    width: fit-content;
    margin-left: 0;
  }

  .home-body-text-container {
    border-radius: 0 30px 30px 30px;
  }

  .home-body-text-title {
    font-size: 2rem;
  }

  .home-body-text-title b {
    font-size: 2rem;
  }

  .home-body-text-title br {
    display: none;
  }

  .social-icons-container {
    width: 40%;
    margin-left: 0;
  }
}

@media (min-width: 850px) {
  .home-intro-text {
    font-size: 3rem;
  }
  .home-body-text-title {
    font-size: 2.6rem;
  }

  .home-body-text-title b {
    font-size: 2.6rem;
  }

  .home-body-text {
    font-size: 2rem;
  }
}

@media (min-width: 1100px) {
  .home-text-container {
    max-width: 40%;
    z-index: 1;
  }

  .ben-picture {
    right: 0;
  }

  .home-intro-text {
    font-size: 2.5rem;
  }
  .home-body-text-title {
    font-size: 2rem;
  }

  .home-body-text-title b {
    font-size: 2rem;
  }
  .home-body-text-title br {
    display: block;
  }

  .home-body-text {
    font-size: 1.8rem;
  }

  .home-intro-text,
  .home-body-text-title,
  .home-body-text {
    text-align: start;
  }

  .social-icons-container {
    width: 250px;
  }
}

@media (min-width: 1300px) {
  .home-text-container {
    max-width: 45%;
  }
  .home-body-text-title br {
    display: none;
  }
}

@media (min-width: 1550px) {
  .home-text-container {
    margin-left: 15%;
  }

  .home-intro-text {
    font-size: 3rem;
  }
  .home-body-text-title {
    font-size: 2.4rem;
  }

  .home-body-text-title b {
    font-size: 2.4rem;
  }

  .home-body-text {
    font-size: 2rem;
  }
}

@media (min-width: 1800px) {
  .home-text-container {
    max-width: 40%;
    margin-left: 20%;
  }

  .home-body-text-title {
    font-size: 2.5rem;
  }

  .home-body-text-title b {
    font-size: 2.5rem;
  }

  .home-body-text {
    font-size: 2rem;
  }
}

@media (min-width: 2200px) {
  .home-intro-text {
    font-size: 3.5rem;
  }
  .home-body-text-title {
    font-size: 3rem;
  }

  .home-body-text-title b {
    font-size: 3rem;
  }

  .home-body-text {
    font-size: 2.5rem;
  }
}
