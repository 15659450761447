.about-page-container {
  background-color: #e6ccb2;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.about-window-container {
  max-width: 90%;
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 40px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  transition: ease all 0.5s;
}

.about-window-title-container {
  display: flex;
  justify-content: center;
}

.about-window-title-text {
  font-size: 3rem;
  margin: 0;
  padding: 10px 10px 5px 10px;
}

.about-window-main-text-container {
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 10px;
}
p {
  font-family: IBM-Plex-Sans-Thai;
  font-size: 1.2rem;
  padding-bottom: 10px;
  color: #9c6644;
  margin: 0;
}

@media (min-width: 1000px) {
  p {
    font-size: 1.4rem;
  }
}
