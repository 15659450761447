.navbar-container {
  background-color: #ede0d4;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.navbar-content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  height: 80px;
  transition: ease all 0.5s;
}

.navbar-brand-container {
  transition: ease all 0.5s;
}

.navbar-brand-text {
  margin: 0;
}

.navbar-burger-menu-button {
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: none;
  background-color: #ede0d4;
}

.navbar-burger-links-container {
  position: absolute;
  background-color: #ede0d4;
  border-left: 5px solid #e6ccb2;
  border-bottom: 5px solid #e6ccb2;
  min-width: 250px;
  right: 0;
  top: 80px;
  z-index: 1;
}

.navbar-burger-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-burger-container:hover .navbar-burger-links-container {
  display: block;
}

.navbar-burger-menu-button:hover {
  background-color: #edd7c2;
}

.navbar-burger-menu-button:active {
  background-color: #e6ccb2;
}

.navbar-link-container {
  display: flex;
  height: 100%;
  justify-content: end;
  align-items: center;
  transition: ease all 0.5s;
}

.navbar-link,
.navbar-burger-link,
.navbar-link-brand {
  text-decoration: none;
}

.navbar-link {
  font-family: IBM-Plex-Sans-Thai;
  padding-left: 50px;
  font-size: 32px;
  color: #9c6644;
  display: inline-block;
  transition: ease all 0.5s;
}

.navbar-burger-link {
  font-family: IBM-Plex-Sans-Thai;
  font-size: 32px;
  color: #9c6644;
  display: block;
  text-align: center;
  transition: ease all 0.5s;
}

.navbar-burger-link:hover {
  background-color: #edd7c2;
}

.navbar-burger-link:active {
  background-color: #e6ccb2;
}

.navbar-link:hover {
  font-size: 40px;
  font-weight: bold;
  transition: font-size 0.25s ease-out;
}

.navbar-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  top: 80px;
  left: 0;
  background-color: #9c6644;
  transform-origin: bottom;
  transition: transform 0.25s ease-out;
}
.navbar-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom;
}

@media only screen and (max-width: 768px) {
  .navbar-content {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 500px) {
  .navbar-link {
    display: none;
  }

  .navbar-burger-menu-button {
    display: block;
  }
}

@media (min-width: 1550px) {
  .navbar-content {
    margin-left: 100px;
    margin-right: 100px;
  }
}
